
const config = {
    // BACKEND_BASE_URL: `http://192.168.1.32:8000/`, // Khushbu
    // BACKEND_BASE_URL: `http://192.168.0.49:8000/`, //Omkar
    // BACKEND_BASE_URL: `https://stagebackend.bewellacyhealth.com/`, // STAGE
    BACKEND_BASE_URL: `https://stagingbackend.bewellacyhealth.com/`, // New
    // BACKEND_BASE_URL: `https://prodbackend.bewellacyhealth.com/`, // Prod
    // BACKEND_BASE_URL: `https://qabackend.bewellacyhealth.com/`, // QA
    // BACKEND_BASE_URL: `https://devbackend.bewellacyhealth.com/`, // Dev
    // BACKEND_BASE_URL : 'https://f184f5fc451c2155824fe472e3a6190a.serveo.net/',
    // BACKEND_BASE_URL : `http://localhost:8000/`,
    // WEBSOCKET_BASE_URL: `wss://devbackend.bewellacyhealth.com`,
    WEBSOCKET_BASE_URL: `wss://stagingbackend.bewellacyhealth.com`,
    // WEBSOCKET_BASE_URL: `wss://qabackend.bewellacyhealth.com`,
    // WEBSOCKET_BASE_URL: `wss://prodbackend.bewellacyhealth.com`,

    TEMPLATE_URL: `https://ccm-profile.s3.us-east-2.amazonaws.com/templates/patient_bulk_template.xlsx`,
    CONSENT_FORM_TEMPLATE: `https://ccm-profile.s3.us-east-2.amazonaws.com/templates/consent_form_template.pdf`

    // TEMPLATE_URL: `https://ccm-prod-profile.s3.us-east-2.amazonaws.com/templates/consent_form_template.pdf`,
    // CONSENT_FORM_TEMPLATE: `https://ccm-prod-profile.s3.us-east-2.amazonaws.com/templates/patient_bulk_template.xlsx`
};

export default config;