import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, Select, TextField, MenuItem, CircularProgress } from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { DatePicker } from '../../../../../../../../../ui-component/DatePicker/DatePicker';
import { PatientApi } from '../../../../../../../../../service/ProviderGroup/Patient';
import cardStyle from '../../../../../../../../../theme/card-layout';
import { useParams } from 'react-router-dom';
import { useNotification } from '../../../../../../../../../hooks/use-notification';
import SelectDropdown from '../../../../../../../../../ui-component/SelectDropdown';

const CareTeamForm = ({ setOpenDrawer, setRefreshList, editData, edit, setEdit }) => {
  const { id } = useParams();
  const { notifySuccess, notifyError } = useNotification();
  const [onSetDate, setOnSetDate] = useState(new Date());
  const [listCareTeamProviders, setListCareTeamProviders] = useState([]);
  console.log("listCareTeamProviders", listCareTeamProviders);
  const [loading, setLoading] = useState(false);
  const [providerGroupId, setProviderGroupId] = useState(JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.uuid);

  useEffect(() => {
    let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
    if (userType == "provider") {
      setProviderGroupId(JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.uuid);
    } else {
      setProviderGroupId(JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid);
    }
    if (providerGroupId) {
      fetchAllCareTeams();
    }
  }, [providerGroupId])

  const fetchAllCareTeams = async () => {
    console.log("I am calling");
    const query = {
      role: "Care Team",
      patient: id
    };
    PatientApi.getCareTeamProviders(providerGroupId, query)
      .then((response) => {
        setListCareTeamProviders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Care Team Providers data:", error);
      });
  };


  const careTeamFields = [
    {
      label: "Search Provider",
      fieldName: "provider_name",
      fieldType: Select,
      placeholder: "Select",
      // options: listCareTeamProviders
      options: [
        ...(listCareTeamProviders?.map(provider => ({
          label: `${provider?.first_name} ${provider?.last_name}`,
          name: `${provider?.uuid}`
        })) || [])
      ],
      disable: edit
    },
    {
      label: "Date",
      fieldName: "date",
      fieldType: TextField,
      placeholder: "Enter Date",
    },
    {
      label: "Note",
      fieldName: "note",
      fieldType: TextField,
      placeholder: "Enter Note",
    },
  ]

  const validationSchema = Yup.object().shape({
    provider_name: Yup.object().required("Provider is required"),
    // date: Yup.string().required("Date is required"),
    note: Yup.string().required("Note is required"),
  });


  return (
    <>
      <Formik
        initialValues={{
          provider_name: edit ? {label:`${editData?.provider?.first_name} ${editData?.provider?.last_name}`} : "",
          date: onSetDate,
          note: edit? editData?.note : ""
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => {
          setLoading(true);
          console.log("Care Data", data);
          if (!edit)
          {
            const payload = {
            provider: data?.provider_name?.name,
            note: data?.note
          }
          PatientApi.assignCareTeam(id, payload)
            .then((response) => {
              notifySuccess('Care team assigned successfully');
              setOpenDrawer(false);
              setRefreshList(true);
              setLoading(false);
              console.log("122", response)
            })
            .catch((error) => {
              console.error("Error fetching assigning care team data:", error);
              notifyError(error?.response?.data?.error);
            });}
          else {
            const payload = {
              note: data?.note
            }
            PatientApi.updateCareNote(id, editData?.provider?.uuid, payload)
            .then((response) => {
              notifySuccess('Care team note updated successfully');
              setOpenDrawer(false);
              setRefreshList(true);
              setLoading(false);
              setEdit(false);
              console.log("122", response)
            })
            .catch((error) => {
              console.error("Error fetching assigning care team data:", error);
              notifyError(error?.response?.data?.error);
            })
          }
        }}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <Grid container justifyContent="flex-end" sx={{ display: "flex", alignItems: "end" }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    width: "100px",
                    textTransform: "none",
                  }}
                  onClick={() => { setOpenDrawer(false); setEdit(false) }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    marginLeft: "20px",
                    width: "120px",
                    color: "#ffffff",
                    textTransform: "none",
                  }}
                  onClick={handleSubmit}
                >
                  Save &nbsp;
                  {loading && (
                    <CircularProgress
                      size={18}
                      style={{ color: "white" }}
                    />
                  )}
                </Button>
              </Grid>
              <Grid container justifyContent="flex-center" sx={{ display: "flex", alignItems: "center" }} xs={12} marginTop={4}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  Assign a Provider
                </Typography>
                <Grid container spacing={2}>
                  {careTeamFields?.slice(0, 2).map((inputData, index) => (
                    <Grid item xs={edit ? 12 : 6} lg={edit ? 12 : 6} key={inputData?.label || index}>
                      {inputData.fieldName === "date" ? (
                        <>
                          {!edit && <>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              marginBottom: "10px",
                            }}
                          >
                            {inputData?.label}
                          </Typography>
                          <DatePicker
                            onSetDate={onSetDate}
                            setOnSetDate={setOnSetDate}
                            sx={{ marginLeft: "20px" }}
                            disable={true}
                          />
                          </>}
                          <ErrorMessage
                            name={inputData.fieldName}
                            component="div"
                            style={{
                              color: "red",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <SelectDropdown data={inputData}></SelectDropdown>
                          <ErrorMessage
                            name={inputData.fieldName}
                            component="div"
                            style={{
                              color: "red",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </>
                      )}
                    </Grid>
                  ))}
                  {careTeamFields?.slice(2, 3).map((inputData, index) => (
                    <Grid item xs={12} lg={12} key={inputData?.label || index} marginTop={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        {inputData?.label}
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder={inputData?.placeholder}
                        name={inputData?.fieldName}
                        sx={cardStyle}
                        multiline
                        rows={4}
                      />
                      <ErrorMessage
                        name={inputData.fieldName}
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CareTeamForm