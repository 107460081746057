// drawerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  refresh: true,
  updateVitalNote: false,
  updatePatientList: false,
  timeLogNote: "",
  billingRefresh: false
};

const timeLogSlice = createSlice({
  name: 'timeLog',
  initialState,
  reducers: {
    setRefresh: (state, action) => {
      state.refresh = action?.payload;
    },
    saveTimer: (state, action) => {
      state.isSave = action?.payload;
    },
    patientId: (state, action) => {
      state.patientId = action?.payload;
    },
    updateNoteHistory: (state, action) => {
      state.updateVitalNote = action?.payload;
    },
    updateEnrolledPatient: (state, action) => { 
      state.updatePatientList = action?.payload;
    },
    addTimelogNote: (state, action) => { 
      console.log("action?.payload", action?.payload);
      state.timeLogNote = action?.payload;
    },
    BillingRefresh: (state, action) => { 
      state.billingRefresh = action?.payload;
    },
  },
});

export const { setRefresh, saveTimer, patientId, updateNoteHistory, updateEnrolledPatient, addTimelogNote, BillingRefresh} = timeLogSlice.actions;

// export const selectDrawerOpen = (state) => state.drawer.open;

export default timeLogSlice.reducer;