  import React, { useEffect, useState } from "react";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  import { useDispatch } from "react-redux";
  import { useFormik } from "formik";
  import {
    TextField,
    Paper,
    Button,
    Checkbox,
    Grid,
    CircularProgress,
  } from "@mui/material";
  import DataTable from "react-data-table-component";
  import { PatientApi } from "../../../../../../../../service/ProviderGroup/Patient";
  import { ReactDatePicker } from "../../../../../../../../ui-component/ReactDatePicker/react-date-picker";
  import { checkTabPermission, customDataTableStyles } from "../../../../../../../../ccm-constant";
  import { useNotification } from "../../../../../../../../hooks/use-notification";
  import CheckPermission from "../../../../../../../../ui-component/ButtonWrapper";
  import ConfirmBox from "../../../../../../../../ui-component/ConfirmBox";
  import { BillingRefresh } from "../../../../../../../../store/slices/TImeLogSlice";

  const BillingCPT = ({ cptData, cptLoading, setCPTYear, setCPTMonth, cptCodesWithDescriptions }) => {
    console.log("cpt code response??", cptData);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const state = location.state;
    const { id } = useParams();
    const { notifySuccess, notifyError } = useNotification();
    const [data, setData] = useState();
    const [billableObject, setBillableObject] = useState();
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [openCnf, setOpenCnf] = useState(false);
    const [isG0511Checked, setIsG0511Checked] = useState(false);

    // Initialize isSubmitDisabled state based on initial selected month and year
    const currentDate = new Date();
    const initialSelectedDate = new Date();
    const isInitialCurrentMonth =
      initialSelectedDate.getMonth() === currentDate.getMonth() &&
      initialSelectedDate.getFullYear() === currentDate.getFullYear();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(!isInitialCurrentMonth || cptData?.length === 0);

    const formik = useFormik({
      initialValues: {
        selectedCodes: cptCodesWithDescriptions.map((codeObj) =>
          cptData?.some((billable) => billable.label === codeObj.code && billable.applicable)
        ),
        billingDate: "",
      },
      enableReinitialize: true,
      onSubmit: async (values) => {
        const selectedCptCodes = cptCodesWithDescriptions
          .filter((_, index) => values.selectedCodes[index])
          .map((codeObj) => codeObj.code);

        const payload = { applicable_codes: selectedCptCodes };
        console.log("payload", payload);
        dispatch(BillingRefresh(false));
        setLoading(true);
        PatientApi.addCPTCode(id, payload)
          .then((response) => {
            notifySuccess("CPT code updated successfully");
            console.log("cpt code response", response);
            setData(response.data);
            dispatch(BillingRefresh(true));
            setLoading(false);
          })
          .catch((error) => {
            notifyError(error?.response?.data?.error);
            setLoading(false);
            console.error("Error fetching patients data:", error);
          });
      },
    });

    const handleDateChange = (date) => {
      const dateObject = new Date(date);
      setSelectedYear(dateObject);
      setSelectedMonth(dateObject);
      setCPTYear(dateObject);
      setCPTMonth(dateObject);

      const currentDate = new Date();
      const isCurrentMonth = 
        dateObject.getMonth() === currentDate.getMonth() &&
        dateObject.getFullYear() === currentDate.getFullYear();
      
      setIsSubmitDisabled(!isCurrentMonth);
    };

    useEffect(() => {
      dispatch(BillingRefresh(true));
    }, []);

    const columns = [
      {
        name: "Sr. No",
        selector: (row, index) => index + 1,
        width: "10%",
      },
      {
        name: "CPT Code",
        cell: (row, index) => {
          const eligibleItem = cptData?.find((obj) => obj.label === row.code);
          const isEligible = eligibleItem && eligibleItem.value && eligibleItem.value.length > 0;
  
          return (
            <div>
              <Checkbox
                name={`selectedCodes[${index}]`}
                disabled={
                  (!cptData?.some((obj) => obj.label === row.code) || !checkTabPermission("change_patientbilling") || isSubmitDisabled) ||
                  (row.code !== "G0511" && isG0511Checked) || !isEligible
                }
                checked={formik.values.selectedCodes[index]}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (row.code === "G0511") {
                    setIsG0511Checked(isChecked);
                    if (isChecked) {
                      formik.setFieldValue(
                        "selectedCodes",
                        formik.values.selectedCodes.map((_, i) => (i === index ? isChecked : false))
                      );
                    } else {
                      formik.setFieldValue(`selectedCodes[${index}]`, isChecked);
                    }
                  } else {
                    formik.setFieldValue(`selectedCodes[${index}]`, isChecked);
                  }
                }}
              />
              {row.code}
            </div>
          );
        },
      },
      {
        name: "Description",
        selector: (row) => row.description,
        wrap:true
      },
      // {
      //   name: "Eligibility",
      //   selector: (row) => {
      //     const isEligible = cptData?.some((obj) => obj.label === row.code && obj.eligible);
      //     return (
      //       <span
      //         style={{
      //           color: isEligible ? "#0BA800" : "info",
      //           fontWeight: "bold",
      //         }}
      //       >
      //         {isEligible ? "Eligible" : "Not Eligible"}
      //       </span>
      //     );
      //   },
      // },
      {
        name: "Eligibility",
        selector: (row) => {
          const eligibleItem = cptData?.find((obj) => obj.label === row.code);
          const isEligible = eligibleItem && eligibleItem.value && eligibleItem.value.length > 0;
          return (
            <span
              style={{
                color: isEligible ? "#0BA800" : "info",
                fontWeight: "bold",
              }}
            >
              {isEligible ? `Eligible x ${eligibleItem.count}` : "Not Eligible"}
            </span>
          );
        },
      },
    ];

    const handleOpenConfirm = () => {
      setOpenCnf(true);
    };

    const handleConfirmSubmit = () => {
      setOpenCnf(false);
      formik.handleSubmit();
    };

    useEffect(() => {
      const isG0511Present = cptData?.some((billable) => billable.label === "G0511" && billable.applicable);
      setIsG0511Checked(isG0511Present);
    }, []);

    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid item display={"flex"} justifyContent={"flex-end"} mb={2}>
          <ReactDatePicker
            selectedButton={"Month"}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            onMonthHandler={handleDateChange}
            onYearHandler={handleDateChange}
          />
          <CheckPermission codeName={"change_patientbilling"}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              sx={{ marginLeft: 2 }}
              onClick={handleOpenConfirm}
              disabled={isSubmitDisabled}
            >
              Submit
              {loading && (
                <CircularProgress
                  size={18}
                  style={{ color: "white" }}
                />
              )}
            </Button>
          </CheckPermission>
        </Grid>
        {!cptLoading ? (
          <DataTable
            columns={columns}
            data={cptCodesWithDescriptions}
            customStyles={customDataTableStyles}
          />
        ) : (
          <CircularProgress
            color="primary"
            sx={{ marginTop: "13%", marginLeft: "50%" }}
          />
        )}
        <ConfirmBox
          handleClose={() => setOpenCnf(false)}
          open={openCnf}
          handleSubmit={handleConfirmSubmit}
          cptCode="Are you sure you want to apply these code?"
        />
      </form>
    );
  };

  export default BillingCPT;
